import { projects } from './portfolio';
const HELLOS = [
  'bonjour',
  'kia ora',
  'howdy',
  'hey there',
  'hola',
  'ciao',
  'heyyy',
  'welcome',
  'hi',
  'greetings'
];

export { projects, HELLOS };
